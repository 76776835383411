<template>
  <pcg-box>
    <div class="pcg-box-inner-header">
      <i class="pcg-icon-buildings"></i>{{ $t('components.abk_employers_box.title') }}
    </div>
    <div v-if="employers && employers.length" class="employers-container">
      <div v-for="(employer, index) in employers"
           class="d-flex flex-column justify-content-end employer pt-3"
           :key="`employer-${index}`">
        <router-link class="employer-container d-flex flex-column align-items-start m-0"
                     :to="{ name: 'company_show_path', params: { id: employer.id } }">
          <img class="company-logo" :src="companyLogo(employer)" :alt="employer.name"/>
          <label class="employer-name">{{ employer.name }}</label>
        </router-link>
        <div v-if="employer.employerType === 'partner'" class="partner-container">
          <img v-if="contrast" :src="employer.employerTypeLogoNegative" class="img-fluid rounded partner-logo">
          <img v-else :src="employer.employerTypeLogo" class="img-fluid rounded partner-logo">
        </div>
        <router-link class="offers" :to="{ name: 'company_show_path', params: { id: employer.id } }">
          {{ $tc('components.abk_employers_box.offers', employer.offersCount) }}
        </router-link>
      </div>
    </div>
    <div v-else class="pcg-no-records mt-4">
      {{ $t('components.abk_employers_box.no_records') }}
    </div>
  </pcg-box>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AbkEmployersBox',
  props: {
    employers: Array
  },
  computed: {
    ...mapGetters('page', ['contrast'])
  },
  methods: {
    companyLogo (employer) {
      return _.get(employer, ['logo', 'thumb', 'url']) || _.get(employer, ['logo', 'url'])
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  /deep/ .box {
    height: 100%;
  }
  .pcg-box-inner-header {
    margin: 0;
  }
  .company-logo {
    max-height: 63px;
    max-width: 100%;
    width: auto;
    height: auto;
  }
  .employer-name {
    color: $main-color;
    margin: .5em 0 0;
  }
  .offers {
    color: $main-active-color;
    margin: 0;
  }
  .employer-container {
    margin-top: $font-size-l;
  }
  .employer-name, .offers {
    font-size: $font-size-s;
    font-weight: 400;
    cursor: pointer;
  }
  .employers-container {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  }
  .partner-container {
    display: flex;
    align-items: center;
    width: 0;
    height: 0;
    position: relative;
    bottom: 6rem;
    left: 3.7rem;
  }

  .partner-logo {
    max-width: 50px;
    max-height: 20px;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .employer-name {
      color: $main-color;
    }
    .offers {
      color: $main-active-color;
    }
    .partner-text {
      color: $pcg-black;
    }
  }
</style>
