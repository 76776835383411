export const views = {
  admin_dashboard: {
    subtitle: 'Administer',
    title: 'Dashboard'
  },
  attendances: {
    actions: {
      present: 'present'
    },
    attendance_reserve_index: {
      title: 'Reserve list'
    },
    download_list: 'Download attendance list',
    download_reserve_list: 'Download reserve list',
    download_sign_out_list: 'Download sign out list',
    sign_out_list: {
      title: 'Sign out list'
    },
    students_index: {
      title: 'Attendance list'
    }
  },
  career_office_dashboard: {
    events_download_error: 'There was an unexpected error while getting events list',
    unexpected_action_error: 'There was an unexpected error whith action'
  },
  common: {
    save: 'Save'
  },
  companies: {
    companies_index: {
      accepted: 'Accepted',
      for_acceptance: 'Waiting',
      rejected: 'Rejected',
      title: 'List of employers'
    }
  },
  company: {
    carousel_title: 'This is how it looks with us'
  },
  competence_skills: {
    edit: {
      clear: 'Clear selections',
      competences_filter_placeholer: 'Filter competences',
      connections_created: 'Associations created successfully',
      save: 'Save associations',
      skills_filter_placeholer: 'Filter programs',
      subtitle: 'Mapping',
      title: 'Competences and study programs'
    },
    index: {
      add_button: 'Edit associations',
      subtitle: 'Study programs',
      table_header: 'Table of combined competences with programs',
      title: 'Competences'
    },
    labels: {
      competences: 'Competences',
      name: 'Competence',
      skills: 'Programs'
    }
  },
  competences: {
    competences_list: {
      all: 'All',
      connected_with_skills: 'Associated with programs',
      not_connected_with_skills: 'Not associated with programs'
    },
    edit: {
      competence_updated: 'Competence updated successfully',
      subtitle: 'Edit',
      title: 'Competences'
    },
    labels: {
      actions: 'Actions',
      description: 'Description',
      name: 'Name',
      status: 'Status',
      switches: 'Change status'
    },
    list_tabs: {
      all_competences_tab: {
        subtitle: 'All',
        title: 'Competences'
      },
      competences_connected_with_skills_tab: {
        subtitle: 'Related to the programs',
        title: 'Competences'
      },
      competences_not_connected_with_skills_tab: {
        subtitle: 'Not related to directions',
        title: 'Competences'
      }
    },
    new: {
      competence_created: 'The competence was created successfully',
      subtitle: 'New',
      title: 'Competence'
    },
    statuses: {
      active: 'Active',
      inactive: 'Inactive'
    },
    users: {
      no_records: 'No students',
      subtitle: 'Students',
      title: 'Competences'
    }
  },
  curriculum_vitaes: {
    curriculum_vitae_edit: {
      cv_download_error: 'An error occurred while downloading the CV.',
      cv_updated: 'Curriculum Vitae has been successfully updated',
      draft_destroyed: 'The changes have been rejected.',
      draft_info_message: 'The changes shown are from a saved draft.',
      draft_info_title: 'Draft',
      subtitle: 'Edit CV',
      title: 'My Profile',
      unsaved_changes_modal: {
        buttons: {
          cancel: 'Cancel',
          draft: 'Save as draft',
          save: 'Save'
        },
        title: 'You have unsaved changes. Do you want to save them?'
      }
    },
    curriculum_vitae_show: {
      subtitle: 'My CV',
      title: 'My Profile',
      download_pdf: 'Download CV'
    }
  },
  dev_sessions: {
    dev_sessions_index: {
      title: 'Dev login'
    }
  },
  dictionaries: {
    dictionary_types: {
      closed: 'Closed',
      open: 'Open'
    },
    edit: {
      dictionary_type: 'Open Dictionary',
      subtitle: 'Edit',
      title: 'Dictionaries'
    },
    index: {
      subtitle: '',
      table_header: 'Table of available dictionaries',
      title: 'Dictionaries'
    },
    labels: {
      actions: 'Actions',
      created_at: 'Account creation date',
      description: 'Description',
      dictionary_type: 'Dictionary type',
      email: 'E-mail address',
      last_login: 'Last login time',
      name: 'Name',
      recent_words_count: 'New terms'
    }
  },
  dictionary_acceptance_words: {
    acceptance_types: {
      allow_emails: 'Sending messages',
      marketing_emails: 'Sending marketing content',
      null: 'No'
    },
    edit: {
      subtitle: 'Edit',
      title: 'Terms'
    },
    index: {
      delete_word: 'Delete the word',
      subtitle: '',
      table_header: 'Table of available conditions',
      title: 'Terms'
    },
    labels: {
      acceptance_type: 'Sending a message',
      actions: 'Actions',
      blocked_on_profile: 'Blocked on profile',
      name: 'Name',
      registration: 'Registration',
      registration_required: 'Required in registration',
      status: 'Required',
      type: 'Type',
      value: 'Value',
      value_en: 'Value (en)',
      word_type: 'Consent type'
    },
    new: {
      subtitle: 'New',
      title: 'Term'
    },
    statuses: {
      null: 'Optional',
      optional: 'Optional',
      required: 'Required'
    },
    types: {
      cooperation: 'Cooperation',
      cv: 'CV',
      employer: 'Employer',
      offer: 'Offer',
      survey: 'Survey'
    },
    word_types: {
      blocked_on_profile: 'Blocked on profile',
      clause: 'Information clause',
      null: 'Default',
      profiling: 'Profiling',
      rodo: 'RODO'
    }
  },
  dictionary_acceptances: {
    index: {
      labels: {
        actions: 'Actions',
        description: 'Description',
        dictionaryType: 'Dictionary type',
        key: 'Key',
        name: 'Name'
      },
      subtitle: 'Acceptances',
      table_header: 'Table of available dictionaries',
      title: 'Dictionaries'
    }
  },
  dictionary_words: {
    edit: {
      subtitle: 'Edit',
      title: 'Terms'
    },
    index: {
      accepted: 'Accepted',
      add: 'Add a term',
      all: 'All',
      no_records: 'No terms available',
      recent: 'New',
      rejected: 'Rejected',
      subtitle: '',
      table_header: 'Table of available terms',
      title: 'Terms'
    },
    labels: {
      actions: 'Actions',
      status: 'Status',
      switches: 'Change status',
      value: 'Value (pl)',
      value_en: 'Value (en)'
    },
    new: {
      subtitle: 'New',
      title: 'Term'
    },
    statuses: {
      accepted: 'Accepted',
      recent: 'New',
      rejected: 'Rejected'
    }
  },
  edit_company_profile: {
    approvals: 'Consents',
    cooperations: 'Forms of cooperation',
    draft_destroyed: 'The changes have been rejected.',
    draft_info_message: 'The changes shown are from a saved draft.',
    draft_info_title: 'Draft',
    firm_download_error: 'An error occurred while downloading the company profile.',
    firm_download_profile: 'An error occurred while downloading the company profile.',
    firm_profile_updated: 'The company profile has been updated',
    subtitle: 'Edit',
    title: 'Company profile'
  },
  email_confirmation: {
    email_reconfirmation: 'Confirm your registration by clicking on the link sent to the e-mail',
    admin_email_reconfirmation: 'Activation e-mail has been sent to the user',
    modal_text: 'To confirm your identity, please enter the password you used to register your alumni account',
    modal_title: 'Proof of identity',
    reconfirm: 'Send confirmation again',
    student_changed: 'Account converted successfully. You can login now',
    subtitle: '',
    success_confirmation: 'The confirmation of the email address was successful. You can login now',
    title: 'Email confirmation'
  },
  employer_cv: {
    certificates: 'Training, courses, certificates',
    competences: 'Competences',
    diploma_date: 'Date of obtaining the diploma',
    education: 'Education',
    experience: 'Experience',
    introduction: 'Introduction',
    matching_profile: 'Profile perfectly matching your offer',
    show_similar_profiles: 'Show similar profiles',
    title: 'CV catalog',
    with_exam: 'Ended with an exam'
  },
  employer_dashboard: {
    downloading_surveys_error: 'An unexpected error occurred while downloading surveys',
    modal_text: 'To add and view new offers and events, you must be approved by the employer.',
    modal_title: 'Unapproved account',
    title: 'Dashboard'
  },
  events: {
    events_edit: {
      subtitle: 'Edit',
      title: 'Events'
    },
    events_list: {
      all: 'All',
      archived: 'Archive',
      assigned: 'Assigned to me',
      current: 'Current',
      pending: 'Waiting',
      registered: 'Subscribed'
    },
    events_list_similar: {
      title: 'Similar events'
    },
    events_new: {
      no_access_text: 'To add new events, your company profile must be verified by a Career Office employee.',
      subtitle: 'New Event',
      title: 'Events'
    },
    events_show: {
      event_canceled: 'Event canceled',
      event_finished: 'The event has already taken place',
      event_sending_for_publication_error: 'An error occurred while sending the event for publication.<br/> Check that all required fields are completed correctly.',
      event_sent_for_publication: 'The event has been sent for publication',
      no_free_places: 'No vacancies',
      number_of_views: 'Number of views',
      register: 'Subscribe',
      register_reserve: 'Sign up for the reserve list',
      register_confirmation_question: 'Are you sure you want to subscribe for this event?',
      register_reserve_confirmation_question: 'Are you sure you want to subscribe to the reserve list for this event?',
      register_for_event_error: 'An unexpected error occurred while saving to the event',
      registered_for_event: 'You have been subscribed to the event',
      registered_for_event_reserve: 'You have been subscribed to the reserve list to the event',
      show_similar_events: 'Show similar events',
      similar_events: 'Similar events',
      title: 'Events',
      unexpected_error: 'An unexpected error occurred while performing the action',
      unregister: 'Unsubscribe',
      unregister_confirmation_question: 'Are you sure you want to unsubscribe from this event?',
      unregister_from_event_error: 'An unexpected error occurred while unsubscribing from the event',
      unregistered_from_event: 'You have been unsubscribed from the event',
      unregistered_from_event_reserve: 'You have been unsubscribed from the event reserve list',
      unregister_reserve: 'Unsubscribe from the reserve list'
    },
    events_student_registered: {
      subtitle: 'Student subscriptions',
      title: 'Events'
    },
    list_tabs: {
      all_events_tab: {
        subtitle: 'All',
        title: 'Events'
      },
      archived_events_tab: {
        subtitle: 'Archive',
        title: 'Events'
      },
      assigned_events_tab: {
        subtitle: 'Assigned to me',
        title: 'Events'
      },
      current_events_tab: {
        subtitle: 'Current',
        title: 'Events'
      },
      pending_events_tab: {
        subtitle: 'Waiting',
        title: 'Events'
      },
      registered_events_tab: {
        subtitle: 'Subscribed',
        title: 'Events'
      }
    }
  },
  favorite_offers: {
    favorite_offers_index: {
      no_records: "You don't have any favorite ads",
      offers_fetching_error: 'An unexpected error occurred while retrieving offers',
      title: 'Favorite offers'
    }
  },
  login: {
    companies_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elite. Sed auctor scelerisque lectus, eget maximus dolor.',
    companies_title: 'companies | company | companies | companies',
    copyrights: 'Copyrighting.',
    header: 'Academic Career Office',
    login_btn: 'Sign in',
    main_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elite. Sed auctor scelerisque lectus, eget maximus dolor.',
    offers_title: 'job offers | job offer | job offers | job offers',
    students_and_graduates_title: 'students and graduates | student or graduate | students and graduates | students and graduates'
  },
  login_user: {
    subtitle: '',
    title: 'Login',
    wrong_login: 'Incorrect email and password combination'
  },
  messages: {
    index_tabs: {
      draft_messages_tab: {
        title: 'Drafts'
      },
      group_messages_tab: {
        group: 'Group',
        title: 'Messages'
      }
    },
    messages_index: {
      draft: 'Drafts',
      group: 'Group'
    },
    messages_new: {
      subtitle: 'New Message',
      title: 'Messages'
    }
  },
  messages_list: {
    title: 'Inbox'
  },
  new_password: {
    instructions: 'Enter a new password',
    password_saved: 'The password has been successfully updated',
    save: 'Save',
    subtitle: '',
    title: 'New password'
  },
  news: {
    news_edit: {
      news_updated: 'The news has been successfully updated.',
      save: 'Save',
      save_and_publish: 'Save and Publish',
      save_as_draft: 'Save draft',
      subtitle: 'Edit news',
      title: 'News'
    },
    news_index: {
      add_news: 'Add news',
      subtitle: 'News list',
      title: 'News'
    },
    news_list: {
      no_records: 'No news',
      title: 'News'
    },
    news_new: {
      publish: 'Publish',
      save: 'Save',
      save_draft: 'Save draft',
      subtitle: 'New news',
      title: 'News'
    }
  },
  offer_applications: {
    applications_offers: {
      applications_fetching_error: 'An unexpected error occurred while downloading tickets',
      cv_sent: 'CV sent',
      no_records: "You didn't apply for any offer",
      title: 'My submissions'
    }
  },
  offer_filters: {
    error_get_offer_filters: 'An unexpected error occurred while retrieving watched search results.',
    offer_filter_removed: 'The saved search result has been deleted.',
    remove_error: 'An unexpected error occurred while deleting.',
    title: 'Watched search results'
  },
  offer_invitations: {
    list_tabs: {
      all: {
        subtitle: 'All',
        title: 'Invitations from employers'
      },
      archived: {
        subtitle: 'Archive',
        title: 'Invitations from employers'
      },
      current: {
        subtitle: 'Current',
        title: 'Invitations from employers'
      }
    },
    offer_invitations_list: {
      all: 'All',
      archived: 'Archive',
      current: 'Current'
    }
  },
  offers: {
    offers_edit: {
      subtitle: 'Edit',
      title: 'Offers'
    },
    offers_employer_tabs: {
      subtitle: 'Offer',
      title: 'Offers'
    },
    offers_index: {
      show_filters: 'Show filters',
      sort_btn: 'accuracy',
      title: 'search results'
    },
    offers_list: {
      subtitle: 'List',
      title: 'Offers'
    },
    offers_list_similar: {
      title: 'Similar offers'
    },
    offers_matching_to_skill_list: {
      subtitle: 'Matching list',
      title: 'Offers'
    },
    offers_new: {
      no_access_text: "To add new offers, your company's profile must be verified by a Career Office employee.",
      subtitle: 'New offer',
      title: 'Offers'
    },
    offers_not_matching_to_skill_list: {
      subtitle: 'List',
      title: 'Offers'
    },
    offers_show: {
      additional_skills: 'Additional skills',
      applying_on: 'Applicant on',
      competences_headers: {
        career_path: 'resulting from the studies program',
        event: 'resulting from training',
        user_assignment: 'entered by the user'
      },
      custom_competences_headers: {
        driving_license: 'Driving license',
        foreign_language: 'Foreign languages',
        other: 'Orthers'
      },
      cv_matching_offer_percent: 'The percentage of matching your CV to the offer',
      go_back_to_search: 'Return to search',
      offer_sending_for_publication_error: 'An error occurred while sending the offer for publication. <br/>Check that all required fields are correctly completed.',
      offer_sent_for_publication: 'The offer has been sent for publication',
      outdated_offer: 'The offer is out of date',
      see_your_applications_link: 'See a list of your submissions',
      show_similar_offers: 'See similar offers',
      subtitle: 'Job',
      title: 'Offers',
      unexpected_error: 'An unexpected error occurred while performing the action',
      update_date: 'Update date',
      you_applied_for_this_offer: 'You applied for this offer.'
    }
  },
  pages_list: {
    new_page: 'New page',
    subtitle: 'List of pages',
    title: 'CMS'
  },
  recipient_groups: {
    recipient_groups_index: {
      title: 'Recipient groups'
    }
  },
  register_user: {
    register: 'Register',
    title: 'Registration',
    subtitle: '',
    user_registered: 'Registration completed successfully. Confirm your registration by clicking on the link sent to the e-mail'
  },
  reset_password: {
    link_generated: 'A link to reset the password has been sent to the e-mail address provided',
    reset: 'Reset password',
    reset_instructions: 'Enter your email address below and we will send you a link to reset your password',
    subtitle: '',
    title: 'Password reset'
  },
  role_choose: {
    employer_btn: 'Log in as an employer',
    graduate_btn: 'Log in as a graduate'
  },
  simple_messages: {
    simple_messages_index: {
      filter_messages: 'Filter messages',
      no_records: 'No messages',
      title: 'Messages'
    },
    simple_messages_new: {
      subtitle: 'New message',
      title: 'Messages'
    },
    simple_messages_show: {
      title: 'Message'
    },
    statuses: {
      all: 'All',
      read: 'Read',
      recent: 'Unread'
    }
  },
  skills: {
    labels: {
      description: 'Description',
      external_id: 'Id',
      kinds: 'Mode',
      name: 'Name',
      types: 'Type'
    },
    list_tabs: {
      all_skills_tab: {
        subtitle: 'All',
        title: 'Programs'
      },
      skills_connected_with_competences_tab: {
        subtitle: 'Related to competences',
        title: 'Programs'
      },
      skills_not_connected_with_competences_tab: {
        subtitle: 'Not related to competences',
        title: 'Programs'
      }
    },
    skill_competences_index: {
      add_button: 'Edit associations',
      labels: {
        competences: 'Competences',
        name: 'Program',
        skills: 'Program'
      },
      subtitle: 'Competences',
      table_header: 'Table of combined programs with competences',
      title: 'Programs'
    },
    skills_list: {
      all: 'All',
      connected_with_competences: 'Associated with competences',
      not_connected_with_competences: 'Not associated with competences'
    },
    skills_report: {
      subtitle: 'Programs',
      title: 'Competences'
    }
  },
  students: {
    actions: {
      cv: 'Show CV',
      edit_cv: 'Edit CV',
      events: 'Events',
      notes: 'Notes',
      offers: 'Offers'
    },
    students_index: {
      accepted: 'Accepted',
      for_acceptance: 'Waiting',
      rejected: 'Rejected',
      title: 'List of students/graduates'
    },
    student_show: {
      title: 'Student/graduate'
    },
    tabs: {
      cv: 'CV',
      edit_cv: 'Edit CV',
      events: 'Events',
      offers: 'Offers'
    }
  },
  student_dashboard: {
    apprenticeships_and_internships: 'Apprenticeships and internships',
    downloading_surveys_error: 'An unexpected error occurred while downloading surveys',
    search_placeholder: 'Search for positions, companies, keywords ...',
    subtitle: 'FIND WORK OR INTERNSHIP FOR YOURSELF.<br/>SEARCH BASE {count} OFFERS.',
    work: 'Job',
    your_city: 'Your city'
  },
  student_notes: {
    new_note: 'Adding note',
    title: 'Notes'
  },
  student_notes_edit: {
    title: 'Edit note'
  },
  student_notes_new: {
    note_error: 'The note has not been saved. Too many characters.',
    note_saved: 'Note saved successfully',
    save_note: 'Save note',
    title: 'New note'
  },
  surveys: {
    answers: {
      answers_index: {
        no_records: 'No completed surveys',
        subtitle: 'Answers'
      }
    },
    list_tabs: {
      active_surveys_tab: {
        subtitle: 'Active',
        title: 'Surveys'
      },
      completed_surveys_tab: {
        subtitle: 'Filled',
        title: 'Surveys'
      },
      inactive_surveys_tab: {
        subtitle: 'Inactive',
        title: 'Surveys'
      },
      recent_surveys_tab: {
        subtitle: 'New',
        title: 'Surveys'
      }
    },
    surveys_edit: {
      subtitle: 'Edit',
      title: 'Surveys'
    },
    surveys_list: {
      active: 'Active',
      all: 'All',
      completed: 'Filled',
      inactive: 'Inactive',
      recent: 'New'
    },
    surveys_new: {
      subtitle: 'New survey',
      title: 'Surveys'
    },
    surveys_show: {
      creating_answer_success: 'Reply has been sent successfully',
      title: 'Survey',
      validates_success: 'The survey was completed correctly'
    },
    surveys_stats: {
      download_report: 'Download the report of {date}',
      export_answers: 'Export answers',
      exporting_answers_error: 'An unexpected error occurred while exporting the answers.',
      report_filename: 'survey_{id}_answers_{time}.xlsx',
      subtitle: 'Statistics',
      title: 'Surveys'
    }
  },
  test: {
    title: 'Components'
  },
  test2: {
    title: 'Base blocks'
  },
  user_competences: {
    edit: {
      no_competences: 'No competences',
      placeholder: 'Add competence',
      subtitle: 'Edit',
      title: 'Student competences'
    }
  },
  user_messages: {
    user_messages_index: {
      download_error: 'An unexpected error occurred while retrieving messages.',
      no_records: 'No messages',
      title: 'Announcements'
    },
    user_messages_show: {
      download_error: 'An unexpected error occurred while retrieving the announcement.',
      title: 'Announcement'
    }
  },
  users_list: {
    block_user: 'Are you sure you want to block the user',
    labels: {
      activation_mail: 'Send activation mail',
      status_actions: 'Block/Unblock user',
      created_at: 'Account creation date',
      email: 'E-mail address',
      full_name: 'Name and surname',
      last_login: 'Last login time',
      roles: 'Role',
      acceptances: 'Acceptances',
      account_remove: 'Remove account',
      relogin: 'Log in as user'
    },
    no_role: 'No role',
    roles: {
      administrator: 'Administrator',
      career_office: 'Career office worker',
      counselor: 'Counselor',
      employer: 'Employer',
      graduate: 'Graduate',
      recruiter: 'Recruiter',
      student: 'Student'
    },
    title: 'Users',
    subtitle: 'List'
  },
  removed_users_list: {
    labels: {
      created_at: 'Account creation date',
      email: 'E-mail address',
      full_name: 'Name and surname',
      removed_by: 'Removed by',
      roles: 'Role',
      removed_at: 'Removed at',
      remove_reason: "Remove reason"
    },
    no_role: 'No role',
    roles: {
      administrator: 'Administrator',
      career_office: 'Career office worker',
      counselor: 'Counselor',
      employer: 'Employer',
      graduate: 'Graduate',
      recruiter: 'Recruiter',
      student: 'Student'
    },
    title: 'Removed Users',
    subtitle: 'List'
  },
  webpages: {
    webpages_edit: {
      save: 'Save',
      save_and_publish: 'Save and Publish',
      save_as_draft: 'Save draft',
      subtitle: 'Edit page',
      title: 'CMS',
      webpage_updated: 'The website has been successfully updated.'
    },
    webpages_new: {
      publish: 'Publish',
      save: 'Save',
      save_draft: 'Save draft',
      subtitle: 'New page',
      title: 'CMS'
    }
  }
}
