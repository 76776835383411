<template>
  <pcg-box>
    <div v-if="$slots.header" class="pcg-box-inner-header">
      <slot name="header"></slot>
    </div>
    <div class="offer" v-for="offer in offers.slice(0, limit)" :key="`offer-${offer.id}`">
      <div class="d-flex justify-content-between">
        <span v-if="offer.company && offer.company.name" class="offer-company-name">{{ offer.company.name }}<br/></span>
        <div v-if="offer.company && offer.company.employerType === 'partner'" class="partner-container">
          <span class="partner-text">{{ offer.company.employerTypeText }}</span>
          <img v-if="contrast" :src="offer.company.employerTypeLogoNegative" class="img-fluid rounded partner-logo">
          <img v-else :src="offer.company.employerTypeLogo" class="img-fluid rounded partner-logo">
        </div>
      </div>
      <router-link :to="{ name: 'offers_show_path', params: { id: offer.id } }" class="offer-title">
        {{ offer.jobTitle }}
      </router-link><br/>
      <span class="pcg-txt-small-bold offer-location">{{ offer.workplace }}</span>
    </div>
    <!--<div v-if="offers.length > limit" class="all-offers-link">-->
      <!--<router-link :to="allOffersLink">{{ $t('general.show_more') }}</router-link>-->
    <!--</div>-->
  </pcg-box>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AbkOffersBox',
  props: {
    offers: {
      type: Array,
      default: () => []
    },
    limit: {
      type: Number,
      default: 5
    },
    allOffersLink: [String, Object]
  },
  computed: {
    ...mapGetters('page', ['contrast'])
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  .offer:not(:last-child) {
    margin-bottom: $font-size-s;
  }
  .offer-title {
    color: $main-color;
    font-weight: 400;
    text-transform: uppercase;
  }
  .offer-company-name {
    margin-bottom: 3px;
  }
  .all-offers-link {
    width: 100%;
    text-align: right;
    a {
      color: $main-active-color;
    }
  }
  .offer-company-name, .offer-location {
    color: $pcg-dark-gray;
    font-weight: 400;
  }
  .partner-container {
    display: inline-block;
  }

  .partner-text {
    color: $pcg-black;
    font-weight: bold;
    font-size: $font-size-s;
    background-color: rgba(200, 200, 200, 0.5);
    padding: .15rem .4rem;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  .partner-logo {
    max-width: 40px;
    max-height: 20px;
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .offer-title {
      color: $main-color;
    }
    .all-offers-link {
      a {
        color: $main-active-color;
      }
    }
    .offer-company-name, .offer-location {
      color: $pcg-dark-gray;
    }
    .partner-text {
      color: $pcg-black;
    }
  }
</style>
