<template>
  <div class="box-container col-12 col" style="text-decoration: none; color: inherit" :style="[ event.status.value === 'accepted' ? { opacity: '1' } : { opacity: '0.5' } ]">
    <div class="custom-box">
      <div @click="goToEvent" class="img-box d-flex justify-content-center align-items-center clicked">
        <img v-if="event.eventMainImage.url" :src="event.eventMainImage.url" :alt="event.eventTitle" />
        <img v-else-if="event.eventMainImage" src="../../assets/images/event-placeholder.jpg" :alt="event.eventTitle" />
      </div>
      <div @click="goToEvent" class="data clicked">
        <div class="px-3 py-3">
          <div class="d-flex align-items-center type-icon mb-2">
            <i v-if="event.eventType === 'training'" class="fas fa-graduation-cap"></i>
            <i v-else-if="event.eventType === 'fair'" class="fas fa-store"></i>
            <i v-else-if="event.eventType === 'rally'" class="fa fa-users"></i>
            <i v-else-if="event.eventType === 'other'" class="fa fa-globe"></i>
            <i v-else-if="event.eventType === 'counseling'" class="fa fa-briefcase"></i>
            <i v-else class="fas fa-message-plus"></i>
            <span class="event-title ml-2">{{ event.eventTitle }}<br/></span>
          </div>
          <div class="d-flex align-items-center flex-wrap prop-flex">
            <template v-for="property in properties">
              <span v-if="formattedProperty(property, event[property]) !== null" :key="property" class="property-container-tags pcg-txt-small-bold value">{{ formattedProperty(property, event[property]) }}</span>
            </template>
          </div>
        </div>
        <div class="px-3 py-3 bordered">
          <b-col cols="12" class="p-0">
            <b-container class="dates_and_address p-0 d-flex flex-column justify-content-between full-height">
              <div>
                <div v-if="actualEvent" class="d-flex justify-content-start align-items-center mb-0">
                  <b-col cols="1" class="d-flex justify-content-center pl-0">
                    <i style="font-weight: 500;" class="fa fa-clock pcg-lg icon"/>
                  </b-col>
                  <b-col cols="6" class="content p-0">
                    <span class="range pcg-txt-small property">{{ $t('general.from') }} <span class="pcg-txt-small-bold">{{ getFormattedDatesArray('HH:mm')[0] }}</span><span v-if="showOnlyStartDate" style="font-size: 0.97em;" class="range pcg-txt-small property"> - {{ $t('general.to') }} <span class="pcg-txt-small-bold">{{ getFormattedDatesArray('HH:mm')[1] }}</span></span></span>
                    <p class="mb-0">{{ getFormattedDatesArray('dd', true)[0] }}, {{ getFormattedDatesArray('DD MMMM YYYY', true)[0] }}</p>
                  </b-col>
                  <b-col v-if="!showOnlyStartDate" cols="5" class="content p-0">
                    <span class="range pcg-txt-small property">{{ $t('general.to') }} <span class="pcg-txt-small-bold">{{ getFormattedDatesArray('HH:mm')[1] }}</span></span>
                    <p class="mb-0">{{ getFormattedDatesArray('dd', true)[1] }}, {{ getFormattedDatesArray('DD MMMM YYYY', true)[1] }}</p>
                  </b-col>
                </div>
                <div v-if="event.subs.length > 1">
                  <b-col cols="10" offset="1" class="pl-0">
                    <p class="m-0 more_dates">{{ $tc('components.events.abk_event_quick_info.more_dates', event.subs.length - 1) }}</p>
                  </b-col>
                </div>
                <div class="bordered-section"></div>
                <div class="d-flex justify-content-start align-items-center">
                  <b-col cols="1" class="d-flex justify-content-center pl-0">
                    <i class="pcg-icon-location-alt pcg-lg icon"/>
                  </b-col>
                  <b-col cols="10" class="pl-0">
                    <div class="content">{{ fullLocation }}</div>
                  </b-col>
                </div>
              </div>
              <div class="mt-3 justify-content-start">
                <b-col class="d-flex align-items-center pl-0">
                  <img class="company-logo" :src="event.company.logo" :alt="event.company.name"/>
                  <div v-if="event.company.employerType === 'partner'" class="partner-container">
                    <img v-if="contrast" :src="event.company.employerTypeLogoNegative" class="img-fluid rounded partner-logo">
                    <img v-else :src="event.company.employerTypeLogo" class="img-fluid rounded partner-logo">
                  </div>
                  <label class="employer-name">{{ event.company.name }}</label>
                </b-col>
              </div>
            </b-container>
          </b-col>
        </div>
        <div class="px-4 py-2 d-flex" :class="showStatus ? 'justify-content-center' : ''">
          <div v-if="showStatus" class="d-flex align-items-center content">
            <p class="mb-0 ml-2 gray-text">
              {{ $t(`components.events.abk_events_list_box.${currentStatus}`) }}
            </p>
          </div>
          <b-row v-else-if="actualEvent && formattedProperty('currentlyFreePlaces', actualEvent['currentlyFreePlaces']) !== null && !['rejected', 'finished', 'canceled'].includes(event.status.value)">
            <b-col lg="6" cols="12" class="mb-3">
              <div class="property-container-places d-flex align-items-center content" :class="actualEvent.currentlyFreePlaces === 0 ? actualEvent.currentlyReservePlaces === 0 ? 'red-text' : 'orange-text' : 'green-text'">
                <i class="fa fa-user-plus pcg-lg icon"/>
                <p v-if="actualEvent.currentlyFreePlaces === null" class="mb-0 ml-2">
                  {{ $t('components.events.abk_event_quick_info.unlimited_places') }}
                </p>
                <p v-else-if="actualEvent.currentlyFreePlaces !== 0" class="mb-0 ml-2">
                  {{ $tc('components.events.abk_event_quick_info.free_places_left', parseInt(formattedProperty('currentlyFreePlaces', actualEvent['currentlyFreePlaces']))) }}
                </p>
                <p v-else-if="actualEvent.currentlyReservePlaces === null" class="mb-0 ml-2">
                  {{ $t('components.events.abk_event_quick_info.unlimited_reserve_places') }}
                </p>
                <p v-else-if="actualEvent.currentlyReservePlaces !== 0" class="mb-0 ml-2">
                  {{ $tc('components.events.abk_event_quick_info.reserve_places_left', parseInt(formattedProperty('currentlyReservePlaces', actualEvent['currentlyReservePlaces']))) }}
                </p>
                <p v-else class="mb-0 ml-2">
                  {{ $t('components.events.abk_event_quick_info.no_places') }}
                </p>
              </div>
            </b-col>
            <b-col lg="6" cols="12" class="content p-0">
              {{ $t('components.events.abk_events_list_box.registration_start') }} {{ getFormattedDatesArray('dd', true)[2] }}, {{ getFormattedDatesArray('DD MMMM YYYY', true)[2] }}
            </b-col>
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { decamelize } from 'humps'
import ApiEvents from '../../api/events'

export default {
  name: 'AbkEventsListBox',
  data: function () {
    return {
      actualEvent: null
    }
  },
  props: {
    event: Object,
    showEmployerActions: Boolean,
    showCareerOfficeActions: Boolean,
    eventsType: String
  },
  mounted () {
    this.event.subEvents.forEach(sub => {
      if (Date.parse(sub.data.attributes.registrationEnd) > Date.now()) {
        this.actualEvent = sub.data.attributes
      }
    })
    if (!this.actualEvent) {
      this.actualEvent = this.event.subEvents[this.event.subEvents.length - 1].data.attributes
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    properties () {
      return ['eventType', 'instructor']
    },
    daysNamesRange () {
      return this.getRangeString(...this.getFormattedDatesArray('dddd', true).map(day => _.capitalize(day)))
    },
    datesRange () {
      return this.getFormattedDatesRange('DD.MM.YYYY', true)
    },
    timeRange () {
      return this.getFormattedDatesRange('HH.mm')
    },
    fullLocation () {
      return _.compact([this.event.place, this.event.address]).join(', ')
    },
    showOnlyStartDate () {
      return this.getFormattedDate(this.actualEvent.startDate, 'DD.MM.YYYY') === this.getFormattedDate(this.actualEvent.endDate, 'DD.MM.YYYY')
    },
    currentStatus () {
      if (!(this.event.status.value === 'rejected' || this.event.status.value === 'finished' || this.event.status.value === 'canceled') && (this.eventsType === 'archived' || this.eventsType === 'registered')) {
        let status = null
        if (this.eventsType === 'registered') {
          status = this.event.status.value
        } else {
          status = 'finished'
        }
        this.event.subs.forEach(sub => {
          if (sub.status.value === 'canceled') {
            status = 'canceled'
          }
        })
        return status
      } else {
        return this.event.status.value
      }
    },
    showStatus () {
      if (this.currentStatus === 'rejected' || this.currentStatus === 'finished' || this.currentStatus === 'canceled') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    isAccepted (status) {
      return status === 'accepted'
    },
    isNotDraft (status) {
      return status !== 'draft'
    },
    isRecent (status) {
      return status === 'recent'
    },
    goToNewEventView () {
      this.$router.push({ name: 'events_new_path' })
    },
    goToEvent () {
      this.$router.push(`/events/${this.actualEvent.id}/sub_event`)
    },
    formatDate (date) {
      const momentDate = moment(date)
      return momentDate.isValid() ? momentDate.format('DD.MM.YYYY') : null
    },
    formattedProperty (name, value) {
      if (!Object.prototype.hasOwnProperty.call(this.event, name)) {
        return null
      }
      if (name === 'freePlaces' && Object.prototype.hasOwnProperty.call(this.actualEvent, 'currentlyFreePlaces')) {
        return null
      }
      if (['currentlyFreePlaces', 'freePlaces'].includes(name)) {
        const currentlyFreePlaces = Object.prototype.hasOwnProperty.call(this.actualEvent, 'currentlyFreePlaces')
          ? this.actualEvent.currentlyFreePlaces
          : this.actualEvent.freePlaces

        return value === null
          ? this.$t('components.events.abk_event_quick_info.unlimited')
          : `${currentlyFreePlaces}`
      }
      if (name === 'reservePlaces' && Object.prototype.hasOwnProperty.call(this.actualEvent, 'currentlyReservePlaces')) {
        return null
      }
      if (['currentlyReservePlaces', 'reservePlaces'].includes(name)) {
        const currentlyReservePlaces = Object.prototype.hasOwnProperty.call(this.actualEvent, 'currentlyReservePlaces')
            ? this.actualEvent.currentlyReservePlaces
            : this.actualEvent.reservePlaces

        return value === null
            ? this.$t('components.events.abk_event_quick_info.unlimited')
            : `${currentlyReservePlaces}`
      }
      if (name === 'where' && this.placeWithAddress) {
        return this.placeWithAddress
      }
      if (value == null) {
        return null
      }
      if (name === 'cost' && value <= 0) {
        return this.$t('components.events.abk_event_quick_info.free')
      }
      if (name === 'startDate' || name === 'endDate') {
        return this.formatDate(value)
      }
      if (name === 'eventType') {
        return this.$te(`general.event_type.${value}`) ? this.$t(`general.event_type.${value}`) : value
      }
      if (name === 'instructor' && this.event.eventType === 'counseling') {
        return value.fullName
      }

      return value
    },
    decamelizeString (value) {
      return Object.keys(decamelize({ [value]: null }))[0]
    },
    getFormattedDatesRange (format, checkDate = false) {
      return this.getRangeString(...this.getFormattedDatesArray(format, checkDate))
    },
    getRangeString (startValue, endValue) {
      return _.compact([startValue, endValue]).join(' - ')
    },
    getFormattedDatesArray (format, checkDate = false) {
      if (this.actualEvent) {
        const endDate = checkDate && this.showOnlyStartDate ? null : this.actualEvent.endDate
        return [this.actualEvent.startDate, endDate, this.actualEvent.registrationStart].map(date => this.getFormattedDate(date, format))
      }
    },
    getFormattedDate (date, format) {
      const momentDate = moment(date)
      return date && momentDate.isValid() ? momentDate.format(format) : null
    },
    statusIcon (mark) {
      switch (mark) {
        case 'question':
          return 'fas fa-question gray-color'
        case 'times':
          return 'fas fa-times red-color'
        case 'edit':
          return 'pcg-icon-edit gray-color'
        default:
          return 'pcg-icon-check green-color'
      }
    },
    performEventAction (eventId, action) {
      this.$swal({
        title: this.$t('general.are_you_sure'),
        text: this.$t('general.action_cannot_be_reverted'),
        showCancelButton: true,
        confirmButtonText: this.$t(`general.${decamelize(action)}`),
        cancelButtonText: this.$t('general.cancel')
      }).then(result => {
        if (result.value) {
          ApiEvents[action](eventId)
            .then(() => {
              this.$emit('getEvents')
            }).catch(() => {
              this.$toastr.e(this.$t('components.events.abk_events_list.unexpected_error'))
            })
        }
      })
    },
    showAcceptRejectActions (status) {
      return status === 'for_acceptance' && this.showCareerOfficeActions
    }
  },
  watch: {
    event: {
      deep: true,
      handler () {
        this.actualEvent = null
        this.event.subEvents.forEach(sub => {
          if (Date.parse(sub.data.attributes.registrationEnd) > Date.now()) {
            this.actualEvent = sub.data.attributes
          }
        })
        if (!this.actualEvent) {
          this.actualEvent = this.event.subEvents[this.event.subEvents.length - 1].data.attributes
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../../assets/stylesheets/vars";

  .content p {
    line-height: 1rem;
    font-size: .86em;
  }

  .prop-flex {
    grid-row-gap: 1rem;
  }

  .type-icon {
    color: $main-color;
  }

  .fa-message-plus::before {
    content: \f4a8;
  }

  .full-height {
    height: 100%;
  }

  .company-logo {
    max-height: 30px;
    max-width: 100%;
    width: auto;
    height: auto;
  }

  .employer-name {
    color: $main-color;
    margin: .5em 0 0 .5em;
  }

  .partner-container {
    width: 0px;
    height: 0px;
    position: relative;
    bottom: 1.85rem;
    right: .5rem;
    z-index: 3;
  }

  .partner-logo {
    max-width: 35px;
    max-height: 15px;
  }

  .box-container {
    margin-bottom: 20px;
    height: 100%;

    .more_dates {
      font-weight: 900;
      color: $main-color;
    }

    .icon {
      color: $pcg-gray;
    }

    .gray-text {
      color: $pcg-gray;
      text-align: center;
    }

    .red-text p, .red-text i {
      color: $pcg-red;
    }

    .orange-text p, .orange-text i {
      color: $pcg-orange;
    }

    .green-text p, .green-text i {
      color: $pcg-green-color;
    }

    .event-title {
        font-weight: 900;
        text-transform: uppercase;
        color: $main-color;
        flex-basis: 100%;

        /deep/ i {
            font-size: $font-size-l;
            margin-right: .5rem;
        }
    }

    .property {
        color: $pcg-light-gray;
    }

    .places p, .places .fa.icon {
        color: $pcg-green-color;
    }

    .places .fa.icon {
        margin-right: 0.7rem;
    }

    .custom-box {
      background: $pcg-white;
      border-radius: 15px;
      -webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
      box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
      overflow: hidden;
      height: 100%;
      display: grid;
      grid-template-rows: 215px auto;

      .data {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
        // grid-template-rows: 30% 55% 14%;

        .bordered {
          border-top: 1px solid $pcg-lightest-gray;
          border-bottom: 1px solid $pcg-lightest-gray;
        }
      }

      .img-box {
        height: 215px;
      }

      & > div > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .property-container-tags {
        padding: .2rem;
        background-color: $pcg-lightest-gray;
        color: $pcg-gray;
        display: block;
        margin-right: 1rem;
        font-size: 0.69em;
        font-weight: 500;
        line-height: 1rem;
        width: max-content;
      }
    }
  }

  .event:not(:last-child) {
    margin-bottom: $font-size-s;
  }
  .event-title {
    color: $main-color;
    font-weight: 400;
    text-transform: uppercase;
  }
  .event-date {
    font-size: $font-size-s;
    margin-bottom: 3px;
  }
  .all-events-link {
    width: 100%;
    text-align: right;
    a {
      color: $main-active-color;
    }
  }
  .event-date, .event-location {
    color: $pcg-dark-gray;
    font-weight: 400;
  }
  .status-icon {
    display: inline-block;
    width: 28px;
  }
  .status-text {
    font-size: $font-size-s;
    font-weight: 700;
    color: $pcg-light-gray;
  }
  .green-color {
    color: $pcg-green-color;
  }
  .gray-color {
    color: $pcg-light-gray;
  }
  .red-color {
    color: $pcg-red;
  }
  .clicked {
    cursor: pointer;
  }
  .bordered-section {
    border-top: 1px solid $pcg-lightest-gray;
    margin: 1rem -1rem;
  }
</style>
<style lang="scss">
  @import "../../assets/stylesheets/vars-contrast";
  .contrast{
    .type-icon {
      color: $main-color;
    }
    .employer-name {
      color: $main-color;
    }
    .box-container {
      .more_dates {
        color: $main-color;
      }
      .icon {
        color: $pcg-gray;
      }
      .gray-text {
        color: $pcg-gray;
        text-align: center;
      }
      .red-text p, .red-text i {
        color: $pcg-red;
      }
      .orange-text p, .orange-text i {
        color: $pcg-orange;
      }
      .green-text p, .green-text i {
        color: $pcg-green-color;
      }
      .event-title {
        color: $main-color;
      }
      .property {
        color: $pcg-light-gray;
      }
      .places p, .places .fa.icon {
        color: $pcg-green-color;
      }
      .custom-box {
        background: $pcg-white;

        .data {
          .bordered {
            border-top: 1px solid $pcg-lightest-gray;
            border-bottom: 1px solid $pcg-lightest-gray;
          }
        }
        .property-container-tags {
          background-color: $pcg-lightest-gray;
          color: $pcg-gray;
        }
      }
    }
    .event-title {
      color: $main-color;
    }
    .all-events-link {
      a {
        color: $main-active-color;
      }
    }
    .status-text {
      color: $pcg-light-gray;
    }
    .green-color {
      color: $pcg-green-color;
    }
    .gray-color {
      color: $pcg-light-gray;
    }
    .red-color {
      color: $pcg-red;
    }
    .bordered-section {
      border-top: 1px solid $pcg-lightest-gray;
    }
  }
</style>
