<template>
  <div class="pcg-footer-container">
    <router-link :to="{ name: 'root_path' }" class="col-12 col-lg-3 text-center text-lg-left pl-lg-0">
      <img :src="logo" class="logo" alt="Graphic Logo" />
      <img :src="negativeLogo" class="logo-negative" alt="Graphic Logo" />
    </router-link>
    <div class="configurable-text-container col-12 col-lg-6 text-center">
      <span class="pcg-footer-text" v-if="upperText" v-html="upperText"></span>
      <br v-if="upperText && bottomText"/>
      <span class="pcg-footer-text" v-if="bottomText" v-html="bottomText"></span>
    </div>
    <span class="pcg-footer-text col-12 col-lg-3 text-center text-lg-right pr-lg-0">Copyright © PCG Academia</span>
  </div>
</template>

<script>
export default {
  name: 'AbkLayoutFooter',
  props: {
    upperText: String,
    bottomText: String,
    logo: String,
    negativeLogo: String
  }
}
</script>

<style lang="scss" scoped>
@import "../assets/stylesheets/vars";

.configurable-text-container {
  line-height: 1;
  text-align: center;
  span {
    width: 100%;
    font-size: $font-size-s;
  }
}
.pcg-footer-container {
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  z-index: 1;
  background-color: transparent;
  height: fit-content;
}
.logo-negative {
  display: none;
}
@media(max-width: $screen-sm-max) {
  .pcg-footer-container {
    flex-direction: column;
  }
}

</style>

<style lang="scss" scoped>
.contrast{
  .logo-negative {
    display: inline-block;
  }
  .logo{
    display: none;
  }
}

</style>
