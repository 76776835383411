<template>
  <pcg-box class="message" :class="{ 'dimmed': !highlighted }" @click.native="$emit('messageClick')">
    <div class="abk-important-stamp" v-if="important">
      <span>!</span>
    </div>
    <router-link v-if="threadId && threadPath"
                 class="pcg-link pcg-link-darken abk-show-thread"
                 v-b-tooltip.hover
                 :title="$t('components.abk_message.show_thread')"
                 :to="{ name: threadPath, params: { id: threadId } }">
      <i class="far fa-comments" />
    </router-link>
    <b-row>
      <b-col cols="12" sm="4" lg="3" xl="2" class="avatar-column">
        <pcg-avatar size="small"
                    ref="logoImage"
                    class="sender-avatar"
                    :class="{ 'career-office-logo': !senderAvatar, 'show-currsor': userProfileId && userProfilePath }"
                    :name="senderName"
                    :role="senderCompany"
                    :no-border="true"
                    @click.native.stop="goToUserProfile"
                    :img="senderAvatar || image"
                    :company-img="senderCompanyImg"/>
        <div v-if="company && company.employerType === 'partner'" ref="partnerContainer" style="width: 0; height: 0; position: absolute; top: 0; left: 0; display: none">
          <img v-if="contrast" :src="company.employerTypeLogoNegative" class="img-fluid rounded" style="max-height: 30px; max-width: 50px;">
          <img v-else :src="company.employerTypeLogo" class="img-fluid rounded" style="max-height: 30px; max-width: 50px;">
        </div>
        <div v-if="isDeleted">
          <h6 class="text-center text-danger my-1">{{ $t('general.account_removed') }}</h6>
        </div>
      </b-col>
      <b-col class="message-content d-flex flex-column">
        <p class="top-bar">
          <span class="sender-name">{{ senderName }}</span>
          <span class="message-date">{{ formatDate(date) }}</span>
        </p>
        <div ref="messageText" class="message-text" :class="{ unread: highlighted, collapsed: !show && showExpander }">
          <div class="message-title" v-if="title">{{ title }}</div>
          <div ref="messageTextDiv" v-html="message"></div>
          <br/>
          <abk-attachment v-for="attachment in attachments"
                          :key="attachment.id"
                          class="message-attachment"
                          @click="downloadAttachment(attachment)">
            {{ attachment.filename }}
          </abk-attachment>
          <div class="expander-btn" :class="{ collapsed: !show }" @click.stop="toggleShow" v-if="showExpander">
            {{ show ? $t('general.collapse') : $t('general.expand') }}
          </div>
        </div>
        <div class="d-flex flex-wrap mt-auto">
        </div>
      </b-col>
    </b-row>
  </pcg-box>
</template>

<script>
import WindowResizeMixin from '../mixins/window_resize'
import AbkAttachment from './offers/AbkAttachment'
import ApiMessageAttachments from '../api/message_attachments'
import { saveAs } from 'file-saver'
import { mapGetters } from 'vuex'

export default {
  name: 'AbkMessage',
  props: {
    date: [Date, String],
    senderName: String,
    senderCompany: String,
    senderAvatar: String,
    senderCompanyImg: String,
    highlighted: Boolean,
    important: Boolean,
    message: String,
    title: String,
    isDeleted: Boolean,
    userProfileId: [String, Number],
    userProfilePath: String,
    threadId: String,
    threadPath: String,
    attachments: {
      type: Array,
      default: () => []
    },
    company: Object
  },
  mixins: [WindowResizeMixin],
  components: { AbkAttachment },
  data: function () {
    return {
      messageTextDivHeight: 0,
      show: false,
      image: require('../assets/images/carrer_office_logo.png')
    }
  },
  mounted () {
    this.updateMessageTextDivHeight()
    this.updatePartnerPosition()
  },
  watch: {
    windowWidth (newValue) {
      this.updateMessageTextDivHeight()
      this.updatePartnerPosition()
    },
    message (newValue) {
      this.updateMessageTextDivHeight()
      this.updatePartnerPosition()
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    showExpander () {
      return this.messageTextDivHeight > 112
    }
  },
  methods: {
    updatePartnerPosition () {
      if (this.$refs.partnerContainer && this.$refs.logoImage) {
        this.$refs.partnerContainer.style.left = ((this.$refs.logoImage.$el.clientWidth + this.$refs.logoImage.$el.offsetLeft) - 30) + 'px'
        this.$refs.partnerContainer.style.top = this.$refs.logoImage.$el.offsetTop + 5 + 'px'
        this.$refs.partnerContainer.style.display = 'block'
        setTimeout(() => {
          this.$refs.partnerContainer.style.left = ((this.$refs.logoImage.$el.clientWidth + this.$refs.logoImage.$el.offsetLeft) - 30) + 'px'
          this.$refs.partnerContainer.style.top = this.$refs.logoImage.$el.offsetTop + 5 + 'px'
          this.$refs.partnerContainer.style.display = 'block'
        }, 1000)
      }
    },
    goToUserProfile () {
      if (this.userProfileId && this.userProfilePath) {
        this.$router.push({ name: this.userProfilePath, params: { id: this.userProfileId } })
      }
    },
    formatDate (date) {
      const momentDate = moment(date)
      return date && momentDate.isValid() ? momentDate.calendar() : null
    },
    updateMessageTextDivHeight () {
      this.messageTextDivHeight = this.$refs.messageTextDiv ? this.$refs.messageTextDiv.offsetHeight : 0
      setTimeout(() => {
        this.messageTextDivHeight = this.$refs.messageTextDiv ? this.$refs.messageTextDiv.offsetHeight : 0
      }, 500)
    },
    downloadAttachment ({ filename, id }) {
      ApiMessageAttachments.getAttachment(id)
        .then(response => {
          const blob = new Blob([response.request.response], { type: response.headers['content-type'] })
          saveAs(blob, filename)
        })
        .catch(error => {
          console.error(error)
          this.$toastr.e(this.$t('components.abk_message.attachment_download_error'))
        })
    },
    toggleShow () {
      this.show = !this.show
      const element = this.$refs.messageText
      if (this.show) {
        const sectionHeight = element.scrollHeight
        element.style.height = sectionHeight + 'px'
        const handler = function (e) {
          element.removeEventListener('transitionend', handler)
          element.style.height = null
        }
        element.addEventListener('transitionend', handler)
      } else {
        const sectionHeight = element.scrollHeight
        const elementTransition = element.style.transition
        element.style.transition = ''
        requestAnimationFrame(function () {
          element.style.height = sectionHeight + 'px'
          element.style.transition = elementTransition
          requestAnimationFrame(function () {
            element.style.height = 111 + 'px'
          })
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

  /deep/ .pcg-avatar-container {
    cursor: pointer;
  }
  /deep/ .box {
    padding-top: $font-size-m !important;
    padding-bottom: $font-size-m !important;
    position: relative;
    overflow: hidden;
  }
  .box-container {
    &:not(.dimmed) {
      .sender-avatar {
        ::v-deep .pcg-avatar-image {
          transform: scale(0.6);
        }
      }
    }
    &.dimmed {
      /deep/ .box {
        background: $pcg-ghost-white;
        box-shadow: $pcg-dimmed-box-shadow !important;
      }
    }
  }
  .avatar-column {
    padding: 0 $font-size-l;
    margin-right: $font-size-l;
    max-width: 160px;
    @media(max-width: $screen-xs-max) {
      max-width: initial;
    }
  }
  .message-content {
    min-width: 170px;
  }
  .message-date {
    color: $pcg-light-gray;
    font-weight: 400;
  }
  .message-text {
    line-height: 1.2;
    font-weight: 400;
    color: $pcg-dark-gray;
    /deep/ a {
      color: $main-active-color;
    }
    position: relative;
    margin-bottom: 10px;
    transition: height 0.3s ease-out;
    height: auto;
    &.unread {
      font-weight: bold;
    }
    &.collapsed {
      height: 111px;
      overflow: hidden;
      &:before {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 100%;
        width: 100%;
        content: "";
        background: linear-gradient(to top, $pcg-ghost-white -4%, rgba(255, 255, 255, 0) 90%);
        pointer-events: none;
      }
      &.unread {
        &:before {
          background: linear-gradient(to top, rgba(255, 255, 255, 1) -4%, rgba(255, 255, 255, 0) 90%);
        }
      }
    }
  }
  .expander-btn {
    text-align: center;
    color: $main-color;
    font-weight: bold;
    position: absolute;
    left: 0;
    right: 0;
    bottom: -14px;
    &.collapsed {
      bottom: 2px;
    }
    &:hover {
      cursor: pointer;
    }
  }
  .abk-important-stamp {
    position: absolute;
    left: -12px;
    top: -12px;
    background: $pcg-the-lightest-gray;
    width: 52px;
    height: 52px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: $pcg-red;
      font-size: $font-size-xl;
      font-weight: bold;
      position: relative;
      top: 4px;
      left: 5px;
    }
  }
  .abk-show-thread {
    position: absolute;
    right: 20px;
    top: 10px;
    color: $main-color;
    font-size: $font-size-xl;
    z-index: 1;
    &:hover {
      color: $hover-color;
    }
  }
  .message-title {
    font-size: $font-size-m;
    color: $main-color;
    font-weight: bold;
    margin-bottom: $pcg-m-sm;
  }
  /deep/ .abk-avatar-container {
    &.career-office-logo {
      padding-top: 24px;

      .abk-avatar-image {
        height: 71px;
        padding: 0px;
        background-color: transparent;
        border-radius: 0 !important;
        background-size: auto;
        margin-bottom: 20px;
      }

      .abk-role {
        font-size: $font-size-m !important;
      }
    }
  }
  .sender-name {
    color: $main-color;
    font-weight: 500;
    display: none;
  }
  @media(max-width: $screen-lg-max) {
    .message-footer {
      text-align: center;
    }
  }
  @media(max-width: $screen-sm-min) {
    .sender-avatar {
      display: none;
    }
    .sender-name {
      display: block;
    }
    .top-bar {
      display: inline-flex;
      flex-direction: column;
    }
  }
  .message-attachment {
    margin-right: $pcg-m-lg;
  }
</style>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast{
    .message-date {
      color: $pcg-light-gray;
    }
    .message-text {
      color: $pcg-dark-gray;
      /deep/ a {
        color: $main-active-color;
      }
      &.collapsed {
        &:before {
          background: linear-gradient(to top, $pcg-ghost-white -4%, rgba(0, 0, 0, 0) 90%);
        }
        &.unread {
          &:before {
            background: linear-gradient(to top, rgba(0, 0, 0, 1) -4%, rgba(0, 0, 0, 0) 90%);
          }
        }
      }
    }
    .expander-btn {
      color: $main-color;
    }
    .abk-important-stamp {
      background: $pcg-the-lightest-gray;
      span {
        color: $pcg-red;
      }
    }
    .abk-show-thread {
      color: $main-color;
      &:hover {
        color: $hover-color;
      }
    }
    .message-title {
      color: $main-color;
    }
    .sender-name {
      color: $main-color;
    }
    .box-container {
      &.dimmed {
        /deep/ .box {
          background-color: $pcg-ghost-white !important;
        }
      }
    }
  }
</style>
