<template>
  <b-row>
    <b-col class="text-right report-buttons-container">
      <pcg-btn size="small" :disabled="exportInProgress" @click="exportAnswers">
        <pcg-spinner v-if="exportInProgress" variant="small" class="button-spinner"/>
        <span :class="{'ml-2': exportInProgress }">{{ $t('views.surveys.surveys_stats.export_answers') }}</span>
      </pcg-btn>
      <pcg-btn v-if="surveyAnswersReport" size="small" @click="downloadReport">
        {{ $t('views.surveys.surveys_stats.download_report', { date: formattedSurveyReportDate }) }}
      </pcg-btn>
    </b-col>
    <abk-survey-stats-box-info :surveyId="surveyId" :info="surveyInfo" class="col-12" />
    <abk-survey-stats-box-answers :questions="answersStats" class="col-12"/>
  </b-row>
</template>

<script>
import AbkSurveyStatsBoxInfo from '../../components/surveys/AbkSurveyStatsBoxInfo'
import AbkSurveyStatsBoxAnswers from '../../components/surveys/AbkSurveyStatsBoxAnswers'
import ApiSurveys from '../../api/surveys'
import ApiSurveyAnswersReports from '../../api/survey_answers_reports'
import { saveAs } from 'file-saver'

export default {
  name: 'SurveysStats',
  components: { AbkSurveyStatsBoxInfo, AbkSurveyStatsBoxAnswers },
  data () {
    return {
      surveyId: this.$route.params.id,
      surveyInfo: {
        name: null,
        attributes: [],
        publicationPeriod: {
          from: null,
          to: null
        },
        recipientGroup: null,
        questionsSet: null,
        sentCount: 0,
        completedCount: 0
      },
      answersStats: [],
      exportInProgress: false,
      surveyAnswersReport: null,
      intervalId: null
    }
  },
  computed: {
    formattedSurveyReportDate () {
      if (this.surveyAnswersReport) {
        const momentDate = moment(this.surveyAnswersReport.updatedAt)
        return momentDate.isValid() ? momentDate.format('YYYY.MM.DD-HH:mm') : null
      }
      return null
    },
    formattedSurveyReportFilenameDate () {
      if (this.surveyAnswersReport) {
        const momentDate = moment(this.surveyAnswersReport.updatedAt)
        return momentDate.isValid() ? momentDate.format('YYYYMMDDHHmm') : null
      }
      return null
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  created () {
    this.getSurveyStats()
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.surveys.surveys_stats.title'),
        subtitle: this.$t('views.surveys.surveys_stats.subtitle')
      })
    },
    exportAnswers () {
      this.exportInProgress = true
      ApiSurveys.exportAnswers(this.surveyId)
        .then(() => {
          this.watchExportStatus()
        })
        .catch(error => {
          this.$toastr.e(this.$t('views.surveys.surveys_stats.exporting_answers_error'))
          console.error(error)
        })
    },
    watchExportStatus () {
      if (!this.intervalId) {
        this.intervalId = setInterval(() => {
          this.getSurveyAnswersReportStatus()
        }, 5000)
      }
    },
    getSurveyAnswersReportStatus () {
      ApiSurveys.getAnswersExportStatus(this.surveyId)
        .then(response => {
          const { answersExportStatus, surveyAnswersReport } = response.data.data.attributes

          if (answersExportStatus !== 'in_progress') {
            this.clearCheck()
            this.surveyAnswersReport = surveyAnswersReport
          }
        })
        .catch(error => {
          this.clearCheck()
          this.$toastr.e(this.$t('views.surveys.surveys_stats.exporting_answers_error'))
          console.error(error)
        })
    },
    clearCheck () {
      clearInterval(this.intervalId)
      this.intervalId = null
      this.exportInProgress = false
    },
    downloadReport () {
      ApiSurveyAnswersReports.getReport(this.surveyAnswersReport.id)
        .then(response => {
          const blob = new Blob([response.request.response], { type: response.headers['content-type'] })

          saveAs(blob, this.$t('views.surveys.surveys_stats.report_filename',
            {
              id: this.surveyId,
              time: this.formattedSurveyReportFilenameDate
            }))
        })
        .catch(error => {
          console.error(error)
        })
    },
    getSurveyStats () {
      if (!this.surveyId) { return }

      ApiSurveys.getSurveyStats(this.surveyId)
        .then(response => {
          this.setSurveyStats(response.data.data.attributes)
          this.$store.dispatch('header/setObjectName', this.surveyInfo.name)
        }).catch(error => {
          console.error(error)
        })
    },
    setSurveyStats (data) {
      this.setSurveyInfo(data)
      this.setAnswersStats(data.answersStats)
      this.exportInProgress = data.answersExportStatus === 'in_progress'
      this.surveyAnswersReport = data.surveyAnswersReport
      if (this.exportInProgress) {
        this.watchExportStatus()
      }
    },
    setSurveyInfo (data) {
      this.surveyInfo = {
        ..._.pick(data, ['name', 'recipientGroup', 'sentCount', 'completedCount']),
        publicationPeriod: {
          from: data.initialSentDate,
          to: data.expirationDate
        },
        attributes: [
          data.status,
          ...Object.entries(data)
            .filter(entry => ['requiresAnswer', 'anonymous', 'graduateFate'].includes(entry[0]) && entry[1])
            .map(entry => entry[0])
        ]
      }
    },
    setAnswersStats (data) {
      this.answersStats = data
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../../assets/stylesheets/vars";

  .button-spinner {
    height: 15px !important;
    width: 15px !important;
  }
  .report-buttons-container {
    margin-bottom: $pcg-m-sm;
  }
</style>
