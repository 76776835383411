<template>
  <div class="basic-info-container">
    <h1 class="pcg-form-title col-12 d-flex justify-content-center" style="margin-bottom: 3rem">{{ $t('components.abk_user_login_form.title') }}</h1>
    <b-row>
      <b-col cols="6" class="left-column">
        <b-row class="d-flex justify-content-center">
          <div class="col-8 application-login d-flex justify-content-center" style="margin-bottom: 2rem">{{ $t('components.abk_user_login_form.idp_login') }}</div>
          <img v-if="contrast && !instance.loginLogoNegative" :src="instance.loginLogo" alt="LOGO" class="col-6 img-fluid d-block mx-auto logo-contrast" style="margin-bottom: 2rem"/>
          <img v-else-if="contrast && instance.loginLogoNegative" :src="instance.loginLogoNegative" alt="LOGO" class="col-6 img-fluid d-block mx-auto" style="margin-bottom: 2rem"/>
          <img v-else :src="instance.loginLogo" alt="LOGO" class="col-6 img-fluid d-block mx-auto" style="margin-bottom: 2rem"/>
          <b-col cols="12" class="mt-3 d-flex justify-content-center">
            <pcg-btn class="save-button mr-0" size="small" @click="login">{{ $t('components.abk_user_login_form.university_login') }}</pcg-btn>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row class="d-flex justify-content-center">
          <span class="col-8 application-login">{{ $t('components.abk_user_login_form.application_login') }}</span>
          <pcg-text-input class="col-8"
                          autocomplete="on"
                          type="email"
                          name="email"
                          :label="$t('components.abk_user_registration_form.email')"
                          v-model="value.email"
                          :error-text="getError('email')"
                          :show-error="errorExist('email')"/>
        </b-row>
        <b-row class="d-flex justify-content-center">
          <pcg-text-input class="col-8"
                          autocomplete="off"
                          name="password"
                          type="password"
                          :label="$t('components.abk_user_registration_form.password')"
                          v-model="value.password"
                          :error-text="getError('password')"
                          :show-error="errorExist('password')"/>
        </b-row>
        <b-row>
          <b-col cols="12" class="mt-3 d-flex justify-content-center">
            <pcg-btn class="save-button mr-0" size="small" @click="loginUser()">{{ $t('general.log_in') }}</pcg-btn>
          </b-col>
          <b-col cols="12" class="mt-3 d-flex justify-content-center">
          <span @click="resetPassword" class="reset-password">
            {{ $t('components.abk_user_login_form.reset_password') }}
          </span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import ApiInstances from '../api/instances'
import { mapGetters } from 'vuex'

export default {
  name: 'abk-user-login-form',
  props: {
    value: Object,
    errors: Object
  },
  data: function () {
    return {
      instance: {
        companiesText: null,
        footerLogo: null,
        footerLogoNegative: null,
        footerBottomText: null,
        loginFooter: null,
        loginLogo: null,
        loginLogoNegative: null,
        footerUpperText: null,
        mainText: null,
        menuLogo: null,
        menuLogoNegative: null
      }
    }
  },
  created () {
    ApiInstances.getInstance()
      .then(response => {
        this.instance = response.data.data.attributes
      })
  },
  computed: {
    ...mapGetters('page', ['contrast'])
  },
  methods: {
    getError (field) {
      if (this.errorExist(field)) {
        return this.errors[field].join(', ')
      }
      return ''
    },
    errorExist (field) {
      return !!(this.errors && this.errors[field])
    },
    loginUser () {
      this.$emit('loginUser')
    },
    resetPassword () {
      this.$emit('resetPassword')
    },
    login () {
      window.location = '/users/auth/openid_connect'
    }
  }
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";
  .form-group {
    margin-bottom: $pcg-m-lg;
  }
  .left-column {
    border-right: 2px solid $pcg-gray;
  }
  .reset-password {
    color: $main-active-color;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .application-login {
    color: $pcg-dark-gray;
    font-weight: 400;
    margin-bottom: .5rem;
  }

  .logo-contrast {
    filter: grayscale(100%) invert(100%);
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";
  .contrast {
    .left-column {
      border-right: 2px solid $pcg-gray;
    }
    .reset-password {
      color: $main-active-color;
    }
    .application-login {
      color: $pcg-dark-gray;
    }
  }
</style>
