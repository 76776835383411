<template>
  <div class="container-fluid pcg-page-container">
    <vue-progress-bar/>
    <template v-if="normalLayout">
      <layout-header :logo="instance.menuLogo"
                     :negative-logo="instance.menuLogoNegative"
                     :logoContainerClasses="sidebarMenuClasses"
                     class="my-layout-header"
                     :class="{ 'abk-no-logo-contrast-border': isLoginPath, 'my-pcg-header': !signedIn && isLoginPath, 'my-page-container': !signedIn && isLoginPath }">
        <template v-slot:upper>
          <div class="upper-header-container">
            <pcg-header-fonts class="icon-padding header-fonts"/>
            <pcg-header-contrast class="icon-padding header-fonts"/>
            <pcg-language-selector :supportedLanguages="supportedLanguages"
                                   class="d-flex align-items-center"
                                   @localeChange="reloadPage($event)"/>
          </div>
        </template>
        <template v-slot:bottom>
          <pcg-header-navbar v-if="checkForNetworkError" :class="['header-navbar', { 'hide-menu-btn': renderedNavigationMenuLinksCount === 0 }]">
            <div v-if="signedIn" class="navbar-bottom-container d-flex align-items-center">
              <div class="navbar-bottom-background"/>
              <pcg-header-user-name class="username" :user-name="fullName"/>
              <pcg-header-fonts class="icon-padding header-fonts d-inline d-md-none"/>
              <pcg-header-contrast class="icon-padding header-fonts d-inline d-md-none"/>
              <pcg-header-messages :current-role="currentRole"
                                   :content-path-per-message-type="contentPathPerMessageType"
                                   :messages="formattedMessagesData"
                                   :unreadCount="messages.unreadCount"
                                   class="messages"
                                   @hidden="markAllMessagesAsRead"/>
              <pcg-header-notifications :current-role="currentRole"
                                        :content-path-per-notification-type="contentPathPerNotificationType"
                                        :notifications="formattedNotificationsData"
                                        :unreadCount="notifications.unreadCount"
                                        class="notifications"
                                        @hidden="markAllNotificationsAsRead"/>
              <pcg-header-profile-button :currentRole="currentRole"
                                         :roles="selectableRoles"
                                         :supportedLanguages="supportedLanguages"
                                         :avatar="avatar"
                                         @changeRole="changeCurrentRole"
                                         @localeChange="reloadPage($event)"
                                         @logout="logout"/>
            </div>
            <div v-else class="pcg-toolbar-items pcg-txt-right d-flex align-items-center">
              <pcg-header-fonts class="icon-padding header-fonts d-inline d-md-none"/>
              <pcg-header-contrast class="icon-padding header-fonts d-inline d-md-none"/>
              <pcg-language-selector :supportedLanguages="supportedLanguages"
                                     class="d-inline-flex d-md-none align-items-center"
                                     @localeChange="reloadPage($event)"/>
              <div v-if="!isLoginUserPath" class="d-none d-md-block">
                <pcg-btn @click="applicationLogin" size="small" class="login-button ml-auto">
                  {{ $t('components.abk_header_navbar.login_btn') }}
                </pcg-btn>
                <span class="buttons-separator">{{ $t('general.or') }}</span>
                <pcg-btn size="small" @click="register" variant="additional" class="login-button">
                  {{ $t('components.abk_header_navbar.register_btn') }}
                </pcg-btn>
              </div>
              <div v-else class="d-none d-md-block">
                <pcg-btn size="small" @click="register" variant="additional" class="login-button">
                  {{ $t('components.abk_header_navbar.register_btn') }}
                </pcg-btn>
              </div>
            </div>
          </pcg-header-navbar>
        </template>
      </layout-header>
      <b-row class="pcg-body-container">
        <pcg-navigation-menu ref="navigationMenu"
                             :current-role="currentRole"
                             :signed-in="signedIn"
                             :links="allLinks"
                             :sidebar-menu-classes="sidebarMenuClasses"
                             :class="{ 'abk-login-menu': isLoginPath }"/>
        <div v-if="checkForNetworkError" class="col" :class="{ 'small-margin': !menuOpen, 'main-content-margin': !isLoginPath }">
          <div class="row">
            <pcg-menu-switcher v-show="!isLoginPath" class="menu-switcher"/>
            <div class="pcg-content-container element-scroll" :class="{ 'content-container': instance.loginFooter }">
              <abk-page-background-cover/>
              <div v-if="isLoginPath && !signedIn" class="mobile-login-buttons-container d-block d-md-none">
                <pcg-btn @click="login" size="small" class="login-button ml-auto">
                  {{ $t('components.abk_header_navbar.login_btn') }}
                </pcg-btn>
                <span class="buttons-separator">{{ $t('general.or') }}</span>
                <pcg-btn size="small" @click="register" variant="additional" class="login-button mr-0">
                  {{ $t('components.abk_header_navbar.register_btn') }}
                </pcg-btn>
              </div>
              <transition name="slide" mode="out-in">
                <router-view/>
              </transition>
            </div>
          </div>
          <div class="row align-items-end">
            <abk-second-footer v-if="instance.loginFooter" :content="instance.loginFooter"></abk-second-footer>
            <abk-layout-footer class="layout-footer" :upper-text="instance.footerUpperText"
                           :bottom-text="instance.footerBottomText"
                           :logo="instance.footerLogo"
                           :negative-logo="instance.footerLogoNegative"/>
          </div>
          <transition v-if="showCookiesPolicy" name="fade" mode="out-in">
            <abk-cookies-policy @acceptCookies="acceptCookies"></abk-cookies-policy>
          </transition>
        </div>
      </b-row>
      <abk-footer-navbar v-if="items && items.length > 0"/>
    </template>
    <template v-else>
      <router-view></router-view>
    </template>
    <abk-open-id-front-channel-logout/>
  </div>
</template>

<script>
import AbkOpenIdFrontChannelLogout from '../components/AbkOpenIdFrontChannelLogout'
import CompanyPopupMixin from '../mixins/company_popup'
import CvPopupMixin from '../mixins/cv_popup'
import NavigationMenuLinksMixin from '../mixins/navigation_menu_links'
import supportedLanguages from '../translations/supported_langs'
import AbkPageBackgroundCover from '../components/AbkPageBackgroundCover'
import AbkFooterNavbar from '../components/AbkFooterNavbar'
import PcgLanguageSelector from 'pcg-core-vue/src/components/PcgLanguageSelector'
import { mapGetters } from 'vuex'
import ApiSimpleMessages from '../api/simple_messages'
import ApiUserMessages from '../api/user_messages'
import ApiOfferInvitations from '../api/offer_invitations'
import ApiOfferApplications from '../api/offer_applications'
import ApiOffers from '../api/offers'
import ApiCompanies from '../api/companies'
import ApiEvents from '../api/events'
import ApiDictionaries from '../api/dictionaries'
import ApiDictionaryWords from '../api/dictionary_words'
import ApiInstances from '../api/instances'
import axios from 'axios'
import AbkCookiesPolicy from '../components/AbkCookiesPolicy'
import VueCookies from 'vue-cookies'
import Vue from 'vue'
import AbkSecondFooter from '../components/AbkSecondFooter'
import AbkLayoutFooter from '../components/AbkLayoutFooter.vue'
import { setOptions } from 'vue-gtag'
Vue.use(VueCookies)

export default {
  components: { AbkSecondFooter, AbkCookiesPolicy, AbkFooterNavbar, AbkPageBackgroundCover, AbkOpenIdFrontChannelLogout, PcgLanguageSelector, AbkLayoutFooter },
  mixins: [CompanyPopupMixin, CvPopupMixin, NavigationMenuLinksMixin],
  data: function () {
    return {
      showCookiesPolicy: false,
      isLoading: true,
      supportedLanguages,
      contentPathPerMessageType: {
        simple_message: { name: 'simple_messages_show_path' },
        user_message: { name: 'user_messages_index_path' }
      },
      contentPathPerNotificationType: {
        offer_invitation: { name: 'offers_invitations_path' },
        offer_application: { name: 'offers_employer_show_path', hash: '#applications' },
        offer: { name: 'offers_list_path' },
        event: { name: 'events_list_all_tab_path' },
        company: { name: 'companies_index_for_acceptance_tab_path' },
        dictionary: { name: 'dictionaries_path' },
        user_message: { name: '' }
      },
      messageApisPerRole: {
        student: [ApiSimpleMessages, ApiUserMessages],
        graduate: [ApiSimpleMessages, ApiUserMessages],
        employer: [ApiSimpleMessages, ApiUserMessages],
        career_office: [ApiSimpleMessages],
        counselor: [ApiSimpleMessages]
      },
      notificationApisPerRole: {
        student: [ApiOfferInvitations, ApiUserMessages],
        graduate: [ApiOfferInvitations, ApiUserMessages],
        employer: [ApiOfferApplications],
        career_office: [ApiOffers, ApiCompanies, ApiEvents, ApiDictionaries, ApiDictionaryWords],
        counselor: [ApiEvents]
      },
      renderedNavigationMenuLinksCount: 0,
      instance: {
        companiesText: null,
        footerLogo: null,
        footerLogoNegative: null,
        footerBottomText: null,
        loginFooter: null,
        loginLogo: null,
        loginLogoNegative: null,
        footerUpperText: null,
        mainText: null,
        menuLogo: null,
        menuLogoNegative: null
      }
    }
  },
  computed: {
    ...mapGetters('footerNavbar', ['items']),
    ...mapGetters('auth', ['fullName', 'avatar', 'currentRole', 'roles', 'signedIn']),
    ...mapGetters('notifications', ['messages', 'notifications']),
    ...mapGetters('sidebarMenu', ['sidebarMenuClasses']),
    ...mapGetters('menu', ['menuOpen']),
    ...mapGetters('sidebarMenu', ['navigationMenuClasses']),
    ...mapGetters('environment', ['env', 'status']),
    user_notification_path () {
      if (['student', 'graduate'].includes(this.currentRole)) {
        return 'student_dashboard_path'
      } else if (this.currentRole === 'employer' || this.currentRole === 'recruiter') {
        return 'employer_dashboard_path'
      } else if (this.currentRole === 'career_office') {
        return 'career_office_dashboard_path'
      } else if (this.currentRole === 'counselor') {
        return 'events_list_all_tab_path'
      } else {
        return 'root_path'
      }
    },
    formattedMessagesData () {
      if (this.messages && this.messages.data) {
        const regex = /(<([^>]+)>)/ig
        return this.messages.data.map(message => {
          const iconClass = !this.showCareerOfficeActions && message.type === 'simple_message'
            ? 'pcg-icon-phone'
            : 'pcg-icon-envelope'

          message.content = message.content.replace(regex, '')
          return { ...message, iconClass: iconClass }
        })
      }
      return []
    },
    formattedNotificationsData () {
      if (this.notifications && this.notifications.data) {
        return this.notifications.data.map(notification => {
          if (notification.type === 'dictionary_word') {
            return {
              ...notification,
              iconClass: 'pcg-icon-bell',
              path: { name: 'dictionary_words_recent_path', params: { dictionaryId: notification.dictionaryId } }
            }
          }
          return { ...notification, iconClass: 'pcg-icon-bell' }
        })
      }
      return []
    },
    selectableRoles () {
      return this.roles.includes('student') ? _.difference(this.roles, ['graduate']) : this.roles
    },
    normalLayout () {
      return !this.fullScreenLayout
    },
    fullScreenLayout () {
      return this.$route.name === 'role_choose_path'
    },
    showCareerOfficeActions () {
      return this.currentRole === 'career_office'
    },
    isLoginPath () {
      return ['login_path', 'root_path', 'register_user_path', 'login_user_path'].includes(this.$route.name)
    },
    isLoginUserPath () {
      return ['login_user_path'].includes(this.$route.name)
    },
    checkLoginFooter () {
      return ['login_path', 'root_path'].includes(this.$route.name)
    },
    checkAccessForUnsigned () {
      return ['dev_sessions_path', 'webpages_show_path', 'email_confirmation_path', 'reset_password_path',
        'new_password_path', 'openid_login_path', 'surveys_show_path'].includes(this.$route.name)
    },
    checkForNetworkError () {
      if (!this.signedIn && !this.isLoginPath && !this.checkAccessForUnsigned) return false
      return true
    }
  },
  watch: {
    '$i18n.locale' (newValue) {
      moment.locale(newValue)
    },
    '$route.path': {
      deep: true,
      handler () {
        if (this.signedIn && ['login_path', 'root_path'].includes(this.$route.name)) {
          if (['student', 'graduate'].includes(this.currentRole)) {
            this.$router.push({ name: 'student_dashboard_path' })
          } else if (['employer', 'recruiter'].includes(this.currentRole)) {
            this.$router.push({ name: 'employer_dashboard_path' })
          } else if (this.currentRole === 'career_office') {
            this.$router.push({ name: 'career_office_dashboard_path' })
          } else if (this.currentRole === 'counselor') {
            this.$router.push({ name: 'events_list_all_tab_path' })
          } else if (this.currentRole === 'administrator') {
            this.$router.push({ name: 'users_list_path' })
          }
        }
      }
    }
  },
  created () {
    this.getEnv()
    if (!this.$cookies.get('accepted_cookies')) {
      this.showCookiesPolicy = true
    }
    if (!Vue.$cookies.get('locale')) {
      Vue.$cookies.set('locale', 'pl', '2y')
    }
    ApiInstances.getInstance()
      .then(response => {
        this.instance = response.data.data.attributes
      })
    this.$store.dispatch('header/setSiteTitle', 'Akademickie Biuro Karier')
    this.$store.dispatch('header/clearHeader')
    if (this.signedIn) {
      this.fetchUser()
    }
  },
  mounted () {
    this.$store.dispatch('locale/setInitialLocale', this.supportedLanguages)
    if (!this.isLoginPath) {
      setTimeout(() => {
        this.contentPathPerNotificationType.user_message = { name: this.user_notification_path }
        document.getElementById('app-loader').style.display = 'none'
        this.isLoading = false
      }, 2000)
    }
    if (!this.instance.menuLogo) {
      ApiInstances.getInstance()
        .then(response => {
          this.instance = response.data.data.attributes
        })
    }
  },
  beforeUpdate () {
    this.$nextTick(() => {
      const navMenu = this.$refs.navigationMenu
      this.renderedNavigationMenuLinksCount = navMenu ? navMenu.$el.querySelectorAll('.list-group > li').length : 0
    })
  },
  methods: {
    reloadPage (locale) {
      localStorage.setItem('locale', locale)
      this.$i18n.locale = locale
      this.$router.go(0)
    },
    changeCurrentRole (role) {
      this.$store.dispatch('auth/setCurrentRole', role)
      this.$router.push({ name: 'root_path' })
    },
    login () {
      window.location = '/users/auth/openid_connect'
    },
    applicationLogin () {
      this.$router.push({ name: 'login_user_path' })
    },
    register () {
      this.$router.push({ name: 'register_user_path' })
    },
    logout () {
      const role = this.currentRole
      const endSessionEndpoint = this.env.abkOpEndSessionEndpoint
      this.$store.dispatch('auth/signOut')
        .then(response => {
          // eslint-disable-next-line eqeqeq
          if (this.env.idpType == 'CAS' || this.env.idpType == 'CAS_APEIRON' || process.env.RAILS_ENV === 'development') {
            // eslint-disable-next-line no-constant-condition
            if (role === 'graduate' || role === 'employer' || role === 'recruiter' || process.env.RAILS_ENV === 'development') {
              window.location = '/'
            } else {
              const idToken = response.data.idToken
              // window.location = `${process.env.ABK_OP_END_SESSION_ENDPOINT}?id_token_hint=${idToken}`
              window.location = `${endSessionEndpoint}?id_token_hint=${idToken}`
            }
          } else {
            const idToken = response.data.idToken
            // window.location = `${process.env.ABK_OP_END_SESSION_ENDPOINT}?id_token_hint=${idToken}`
            window.location = `${endSessionEndpoint}?id_token_hint=${idToken}`
          }
        })
        .catch(error => {
          // console.log(error.response.data.message)
          this.$toastr.e(error.response.data.message)
        })
    },
    fetchUser () {
      this.$store.dispatch('auth/fetchUser')
        .then(() => {
          this.checkCompany()
          this.checkCv()
          if ((!Array.isArray(this.$store.getters['auth/roles']) || !this.$store.getters['auth/roles'].length)) {
            this.$router.push({ name: 'role_choose_path' })
          }
        })
        .catch(error => {
          console.log(error.response.data.message)
          this.$toastr.e(error.response.data.message)
        })
    },
    markAllMessagesAsRead () {
      if (this.messages && this.messages.unreadCount > 0) {
        const apis = this.messageApisPerRole[this.currentRole] || []
        axios.all(apis.map(api => api.markAllAsRead()))
          .then(() => {
            this.$store.dispatch('notifications/getAllNotifications')
          }).catch(error => {
            console.error(error)
          })
      }
    },
    markAllNotificationsAsRead () {
      if (this.notifications && this.notifications.unreadCount > 0) {
        const apis = this.notificationApisPerRole[this.currentRole] || []
        axios.all(apis.map(api => api.markAllNotificationsAsRead()))
          .then(() => {
            this.$store.dispatch('notifications/getAllNotifications')
          }).catch(error => {
            console.error(error)
          })
      }
    },
    goToRegistrationPage () {
      this.$router.push(this.env.registrationAddress)
    },
    acceptCookies () {
      Vue.$cookies.set('accepted_cookies', true, '2y')
      this.showCookiesPolicy = false
    },
    async getEnv () {
      await this.$store.dispatch('environment/fetchEnv')
      setOptions({
        enabled: (this.env.googleAnalytics !== null),
        config: {
          id: this.env.googleAnalyticsCode
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "../assets/stylesheets/vars";

  ::v-deep {
    .logo-negatyw {
      filter: grayscale(100%) invert(100%);
    }
  }

  .my-pcg-header {
    ::v-deep .pcg-header-container {
      .pcg-logo-container {
        display: none !important;
      }
    }
  }

  .mobile-login-buttons-container {
    position: fixed;
    background-color: $pcg-white;
    top: 65px;
    right: 0;
    padding-right: 20px;
    width: 100%;
    text-align: right;
    z-index: 1;
    button {
      margin-top: 0;
    }
  }

  @media (min-width: $screen-sm-max) {
    ::v-deep .pcg-header-container {
      .header-background:not(.show) ~ .col {
        .pcg-top-container, .pcg-logged-user-container {
          z-index: 2;
        }

        .pcg-background-transparent {
          .navbar-bottom-container {
            position: relative;
          }
          .navbar-bottom-background {
            z-index: -1;
            display: block;
            background: $pcg-white;
            opacity: .5;
            position: absolute;
            top: -40px;
            left: -30px;
            width: calc(100% + 60px);
            height: 115px;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }
  }
  .header-title {
    margin-bottom: 20px;
    display: block;
    padding-top: 20px;
  }
  .pcg-btn-sidebar-menu {
    height: 45px;
    width: 25px;
    align-items: center;
  }

  @media screen and (min-width: 1051px) {
    .header-title {
      display: none;
    }
  }

  .content-container {
    min-height: calc(100vh - 7.5em - 12.2em) !important;
  }

  ::v-deep .pcg-small {
    img {
      width: 80px;
    }
  }

  .element-scroll {
    z-index: 1;
  }
</style>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  .pcg-btn-sidebar-menu {
    height: 45px;
    width: 25px;
    align-items: center;
  }
  .header-title {
    margin-bottom: 20px;
    display: block;
    padding-top: 20px;
  }
  .icon-padding {
    color: $main-active-color;
    padding-right: 10px;
  }
  .messages, .notifications {
    color: $main-active-color;
    margin-right: 18px;
  }
  .username {
    margin-right: 18px;
  }
  .upper-header-container {
    display: flex;
    align-items: center;
    height: 40px;
  }
  .header-fonts {
    padding-top: 2px;
  }
  .header-navbar.hide-menu-btn {
    ::v-deep .pcg-menu-btn {
      display: none
    }
  }
  @-moz-document url-prefix() {
    .pcg-btn-sidebar-menu {
      height: 46px !important;
    }
  }
  @media screen and (min-width: 1051px) {
    .header-title {
      display: none;
    }
  }

  @media (max-width: $screen-md-max) {
    .icon-padding {
      padding-right: 18px;
    }
  }

  .layout-footer {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  ::v-deep .text-center {
    color: $pcg-dark-gray;
  }

  ::v-deep .no-messages {
    color: $pcg-dark-gray;
  }
</style>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars-contrast';

  .contrast {
    .mobile-login-buttons-container {
      background-color: $pcg-white;
    }
    .icon-padding {
      color: $main-active-color;
    }
    .messages, .notifications {
      color: $main-active-color;
    }
    .pcg-header-container  {
      .navbar-bottom-background {
        background-color: $pcg-white !important;
      }
    }
    .footer-container {
      background-color: $pcg-white;
    }
    ::v-deep .text-center {
      color: $pcg-dark-gray;
    }
    ::v-deep .no-messages {
      color: $pcg-dark-gray;
    }
    .my-layout-header {
      @media screen and (min-width: 768px) {
        /deep/ .header-background.show {
          background: $pcg-the-lightest-gray !important;
          opacity: 1;
        }
      }
    }
  }
</style>
